import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/'
        icon='/media/icons/duotune/arrows/arr001.svg'
        // title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        title='Home Page'
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Applicações</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/event/'
        title='Eventos'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/event/list' title='Lista' hasBullet={true} />
        <AsideMenuItem to='/event/new' title='Novo Evento' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/athlete/'
        title='Atletas'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/athlete/list' title='Lista' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/payment/'
        title='Pagamentos'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/payment/list' title='Lista' hasBullet={true} />
        {/* <AsideMenuItem to='/payment/upload' title='Carregar Ficheiro' hasBullet={true} /> */}
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a className='menu-link' href='#' rel='noreferrer'>
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>
            Versão{' '}
            {process.env.NODE_ENV === 'production'
              ? (window as any).env.REACT_APP_VERSION
              : process.env.REACT_APP_VERSION}
          </span>
        </a>
      </div>
    </>
  )
}
