import React, { lazy } from 'react'
import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import PaymentHeader from './PaymentHeader'

// const FileList = lazy(async () => await import('./components/FileList'))
const InvoiceList = lazy(async () => await import('./components/InvoiceList'))
// const FileUpload = lazy(async () => await import('./components/FileUpload'))

const eventBreadCrumbs: PageLink[] = [
  {
    title: 'Pagamentos',
    path: '/payments',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PaymentPage = (): JSX.Element => (
  <Routes>
    <Route
      element={
        <>
          <PaymentHeader />
          <Outlet />
        </>
      }
    >
      <Route
        path='list'
        element={
          <>
            <PageTitle breadcrumbs={eventBreadCrumbs}>Lista</PageTitle>
            <InvoiceList />
          </>
        }
      />
      {/* <Route
        path='upload'
        element={
          <>
            <PageTitle breadcrumbs={eventBreadCrumbs}>Carregar</PageTitle>
            <FileUpload />
          </>
        }
      /> */}

      <Route index element={<Navigate to='/event/list' />} />
    </Route>
  </Routes>
)

export default PaymentPage
