import { Link } from 'react-router-dom'

const PaymentHeader: React.FC = () => {
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'></div>
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      Pagamentos
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'></div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    'nav-link text-active-primary me-6 ' +
                    (location.pathname === '/payment/list' && 'active')
                  }
                  to={'/payment/list'}
                >
                  Lista
                </Link>
              </li>
              {/* <li className='nav-item'>
                <Link
                  className={
                    'nav-link text-active-primary me-6 ' +
                    (location.pathname === '/payment/upload' && 'active')
                  }
                  to={'/payment/upload'}
                >
                  Carregar
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentHeader
